import React from 'react';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';


const Calc2 = () => {
    const { t, i18n } = useTranslation();

    const [Category, setCategory] = useState([]);  //Категория продуктов

    useEffect(()=>{
        const category = []
        for (const [key, value] of Object.entries(i18n.store.data.en.translation.description.calc2.category)) {
            //     console.log(key, value);
                 let p = {}
                 p[key] = t(`description.calc2.category.${key}`);//value;
                 category.push(p);
        }
        category.sort((a,b)=>(Object.values(a)[0].localeCompare(Object.values(b)[0])));
        setCategory(category);

        console.log(category);
    },[]);
    
    return (
        <>
            {t('description.calc2Name')}
        </>
    );
}


export const xe = {
    
    'main_bread_pastries':{
        'white_ray_bread_except_butter':{'xe':20},
        'black_bread':{'xe':25},
        'bran_bread':{'xe':30},
        'borodinsky_bread':{'xe':15},
        'crackers':{'xe':15},
        'crackers_dry_biscuits':{'xe':15},
        'crispbread':{'xe':20},
        'dry_unsweetened':{'xe':20},
        'lavash':{'xe':20},
        'salty_sticks':{'xe':15},
        'breadcrumbs':{'xe':15},
        'sweet_bun':{'xe':20},
        },
    'main_flour_products':{
        'puff_pastry':{'xe':35},
        'yeast_dough':{'xe':30},
        'pancake_large':{'xe':30},
        'pancakes_with_cottage_cheese':{'xe':50},
        'pancakes_with_meat':{'xe':50},
        'dumplings_with_cottage_cheese':{'xe':50},
        'dumplings':{'xe':50},
        'cheesecake':{'xe':50},
        'waffles_small':{'xe':17},
        'flour':{'xe':15},
        'gingerbread':{'xe':40},
        'pancakes_medium':{'xe':30},
        'butter_cookies':{'xe':15},
        'pasta_raw':{'xe':15},
        'pasta_boiled':{'xe':50},
        },
    'main_cereals':{
        'cereals_any_raw':{'xe':15},
        'porridge_any':{'xe':50},
        'corn_medium':{'xe':100},
        'corn_canned':{'xe':60},
        'corn_flakes':{'xe':15},
        'popcorn':{'xe':15},
        'oatmeal':{'xe':20},
        'wheat_bran':{'xe':50},
        },
    'main_dairy_products':{
        'milk':{'xe':200},
        'baked_milk':{'xe':200},
        'kefir':{'xe':250},
        'cream':{'xe':200},
        'buttermilk':{'xe':300},
        'curdled_milk':{'xe':200},
        'fermented_baked_milk':{'xe':200},
        'sweet_acidophilus':{'xe':200},
        'yogurt_natural':{'xe':200},
        'fruit_yogurt':{'xe':75},
        'milk_ice_cream_without_glaze_and_waffles':{'xe':65},
        'cream_ice_cream_in_glaze_and_waffles':{'xe':50},
        'cheesecake_medium_with_sugar':{'xe':75},
        'curd_mass_sweet_without_icing_and_raisins':{'xe':100},
        'curd_mass_with_raisins_sweet':{'xe':35},
        'condensed_milk_without_sugar':{'xe':130},
        'powdered_milk':{'xe':35},
        },
    'main_vegetables_legumes_nuts':{
        'raw_and_boiled_potatoes_medium':{'xe':75},
        'mashed_potatoes':{'xe':90},
        'fried_potatoes':{'xe':35},
        'french_fries':{'xe':35},
        'potato_pancakes':{'xe':60},
        'chips':{'xe':25},
        'carrots_medium':{'xe':200},
        'beets_medium':{'xe':150},
        'beans_dried':{'xe':20},
        'beans_boiled':{'xe':50},
        'canned_peas':{'xe':100},
        'peas_fresh':{'xe':100},
        'beans_boiled':{'xe':50},
        'lentils_boiled':{'xe':50},
        'nuts':{'xe':60},
        'pumpkin':{'xe':200},
        'jerusalem_artichoke':{'xe':70},
        'avocado':{'xe':200},
        'ketchup':{'xe':40},
        },
    'main_fruit_with_skin':{
        'apricots':{'xe':110},
        'quince_large':{'xe':140},
        'pineapple_cross_section':{'xe':140},
        'watermelon':{'xe':270},
        'orange_medium':{'xe':150},
        'banana_medium':{'xe':70},
        'pomegranate_medium':{'xe':170},
        'grapefruit_large':{'xe':170},
        'pear_small':{'xe':90},
        'melon':{'xe':100},
        'figs':{'xe':80},
        'kiwi_large':{'xe':110},
        'lemon':{'xe':270},
        'mango_small':{'xe':110},
        'tangerines_medium':{'xe':150},
        'nectarine_medium':{'xe':120},
        'peach_medium':{'xe':120},
        'papaya':{'xe':140},
        'persimmon_medium':{'xe':70},
        'apple_small':{'xe':90},
        },
    'main_berries_with_pit_and_skin':{
        'cherry_plum':{'xe':140},
        'cranberries':{'xe':140},
        'grapes_small_berries':{'xe':70},
        'cherry':{'xe':90},
        'blackberry':{'xe':140},
        'strawberries_wild_strawberries_medium_sized_berries':{'xe':160},
        'cranberries':{'xe':120},
        'gooseberry':{'xe':120},
        'raspberry':{'xe':160},
        'plums_small':{'xe':90},
        'currant':{'xe':120},
        'cherry':{'xe':100},
        'blueberries':{'xe':90},
        'wild_rose':{'xe':60},
        },
    'main_dried_fruits':{
        'bananas':{'xe':15},
        'raisins':{'xe':15},
        'figs':{'xe':15},
        'dried_apricots':{'xe':15},
        'dates':{'xe':15},
        'prunes':{'xe':20},
        'apples':{'xe':20},
        },
    'main_nuts':{
        'walnuts':{'xe':50},
        'earthen_with_peel':{'xe':80},
        'earth_peeled':{'xe':60},
        'cedar':{'xe':60},
        'cashew':{'xe':40},
        'pistachios':{'xe':60},
        'hazelnut':{'xe':90},
        },
    'main_juices':{
        'cabbage_juice':{'xe':500},
        'carrot_juice':{'xe':125},
        'cucumber_juice':{'xe':500},
        'beetroot_juice':{'xe':125},
        'tomato_juice':{'xe':300},
        'orange_juice':{'xe':110},
        'grape_juice':{'xe':70},
        'cherry_juice':{'xe':90},
        'pear_juice':{'xe':100},
        'grapefruit_juice':{'xe':140},
        'redcurrant_juice':{'xe':80},
        'gooseberry_juice':{'xe':100},
        'strawberry_juice':{'xe':160},
        'raspberry_juice':{'xe':170},
        'plum_juice':{'xe':80},
        'apple_juice':{'xe':100},
        },
    'main_drinks':{
        'kvass':{'xe':250},
        'sparkling_water_sweet':{'xe':100},
        'kissel':{'xe':250},
        'compote':{'xe':250},
        'light_beer':{'xe':300},
        'non_alcoholic':{'xe':200},
        },
    'main_sweets':{
        'sugar_sand':{'xe':10},
        'sugar_lumpy':{'xe':10},
        'fructose':{'xe':12},
        'chocolate_regular':{'xe':20},
        'sweetener_chocolate':{'xe':30},
        'honey':{'xe':12},
        'sugar_jam':{'xe':10},
        'chocolate_candy':{'xe':15},
        'pudding':{'xe':125},
        'marmalade':{'xe':20}
        }
        
  }
  
  
  
  export const calc_xe = {
        'white_ray_bread_except_butter':{'xe':20},
        'black_bread':{'xe':25},
        'bran_bread':{'xe':30},
        'borodinsky_bread':{'xe':15},
        'crackers':{'xe':15},
        'crackers_dry_biscuits':{'xe':15},
        'crispbread':{'xe':20},
        'dry_unsweetened':{'xe':20},
        'lavash':{'xe':20},
        'salty_sticks':{'xe':15},
        'breadcrumbs':{'xe':15},
        'sweet_bun':{'xe':20},
        'puff_pastry':{'xe':35},
        'yeast_dough':{'xe':30},
        'pancake_large':{'xe':30},
        'pancakes_with_cottage_cheese':{'xe':50},
        'pancakes_with_meat':{'xe':50},
        'dumplings_with_cottage_cheese':{'xe':50},
        'dumplings':{'xe':50},
        'cheesecake':{'xe':50},
        'waffles_small':{'xe':17},
        'flour':{'xe':15},
        'gingerbread':{'xe':40},
        'pancakes_medium':{'xe':30},
        'butter_cookies':{'xe':15},
        'pasta_raw':{'xe':15},
        'pasta_boiled':{'xe':50},
        'cereals_any_raw':{'xe':15},
        'porridge_any':{'xe':50},
        'corn_medium':{'xe':100},
        'corn_canned':{'xe':60},
        'corn_flakes':{'xe':15},
        'popcorn':{'xe':15},
        'oatmeal':{'xe':20},
        'wheat_bran':{'xe':50},
        'milk':{'xe':200},
        'baked_milk':{'xe':200},
        'kefir':{'xe':250},
        'cream':{'xe':200},
        'buttermilk':{'xe':300},
        'curdled_milk':{'xe':200},
        'fermented_baked_milk':{'xe':200},
        'sweet_acidophilus':{'xe':200},
        'yogurt_natural':{'xe':200},
        'fruit_yogurt':{'xe':75},
        'milk_ice_cream_without_glaze_and_waffles':{'xe':65},
        'cream_ice_cream_in_glaze_and_waffles':{'xe':50},
        'cheesecake_medium_with_sugar':{'xe':75},
        'curd_mass_sweet_without_icing_and_raisins':{'xe':100},
        'curd_mass_with_raisins_sweet':{'xe':35},
        'condensed_milk_without_sugar':{'xe':130},
        'powdered_milk':{'xe':35},
        'raw_and_boiled_potatoes_medium':{'xe':75},
        'mashed_potatoes':{'xe':90},
        'fried_potatoes':{'xe':35},
        'french_fries':{'xe':35},
        'potato_pancakes':{'xe':60},
        'chips':{'xe':25},
        'carrots_medium':{'xe':200},
        'beets_medium':{'xe':150},
        'beans_dried':{'xe':20},
        'beans_boiled':{'xe':50},
        'canned_peas':{'xe':100},
        'peas_fresh':{'xe':100},
        'beans_boiled':{'xe':50},
        'lentils_boiled':{'xe':50},
        'nuts':{'xe':60},
        'pumpkin':{'xe':200},
        'jerusalem_artichoke':{'xe':70},
        'avocado':{'xe':200},
        'ketchup':{'xe':40},
        'apricots':{'xe':110},
        'quince_large':{'xe':140},
        'pineapple_cross_section':{'xe':140},
        'watermelon':{'xe':270},
        'orange_medium':{'xe':150},
        'banana_medium':{'xe':70},
        'pomegranate_medium':{'xe':170},
        'grapefruit_large':{'xe':170},
        'pear_small':{'xe':90},
        'melon':{'xe':100},
        'figs':{'xe':80},
        'kiwi_large':{'xe':110},
        'lemon':{'xe':270},
        'mango_small':{'xe':110},
        'tangerines_medium':{'xe':150},
        'nectarine_medium':{'xe':120},
        'peach_medium':{'xe':120},
        'papaya':{'xe':140},
        'persimmon_medium':{'xe':70},
        'apple_small':{'xe':90},
        'cherry_plum':{'xe':140},
        'cranberries':{'xe':140},
        'grapes_small_berries':{'xe':70},
        'cherry':{'xe':90},
        'blackberry':{'xe':140},
        'strawberries_wild_strawberries_medium_sized_berries':{'xe':160},
        'cranberries':{'xe':120},
        'gooseberry':{'xe':120},
        'raspberry':{'xe':160},
        'plums_small':{'xe':90},
        'currant':{'xe':120},
        'cherry':{'xe':100},
        'blueberries':{'xe':90},
        'wild_rose':{'xe':60},
        'bananas':{'xe':15},
        'raisins':{'xe':15},
        'figs':{'xe':15},
        'dried_apricots':{'xe':15},
        'dates':{'xe':15},
        'prunes':{'xe':20},
        'apples':{'xe':20},
        'walnuts':{'xe':50},
        'earthen_with_peel':{'xe':80},
        'earth_peeled':{'xe':60},
        'cedar':{'xe':60},
        'cashew':{'xe':40},
        'pistachios':{'xe':60},
        'hazelnut':{'xe':90},
        'cabbage_juice':{'xe':500},
        'carrot_juice':{'xe':125},
        'cucumber_juice':{'xe':500},
        'beetroot_juice':{'xe':125},
        'tomato_juice':{'xe':300},
        'orange_juice':{'xe':110},
        'grape_juice':{'xe':70},
        'cherry_juice':{'xe':90},
        'pear_juice':{'xe':100},
        'grapefruit_juice':{'xe':140},
        'redcurrant_juice':{'xe':80},
        'gooseberry_juice':{'xe':100},
        'strawberry_juice':{'xe':160},
        'raspberry_juice':{'xe':170},
        'plum_juice':{'xe':80},
        'apple_juice':{'xe':100},
        'kvass':{'xe':250},
        'sparkling_water_sweet':{'xe':100},
        'kissel':{'xe':250},
        'compote':{'xe':250},
        'light_beer':{'xe':300},
        'non_alcoholic':{'xe':200},
        'sugar_sand':{'xe':10},
        'sugar_lumpy':{'xe':10},
        'fructose':{'xe':12},
        'chocolate_regular':{'xe':20},
        'sweetener_chocolate':{'xe':30},
        'honey':{'xe':12},
        'sugar_jam':{'xe':10},
        'chocolate_candy':{'xe':15},
        'pudding':{'xe':125},
        'marmalade':{'xe':20}
        
  }

export default Calc2;