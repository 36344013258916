import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    detection: {
        order: ["path", "localStorage", "htmlTag", "subdomain"],
        lookupFromPathIndex: 0,
        checkWhitelist: true,
      },
    fallbackLng: 'en',
  //  initImmediate: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    preload: ["en", "ru", "es"],
  //  lng:'en',
    resources: {
      en: {
        langName:'English',
        translation: {
          description: {
            part1: 'English text for example',
            part2: 'Learn React',
            calc1Name: 'Weight and volume calculator',
            calc2Name: 'Bread unit calculator',
            calc3Name: 'Calorie calculator',

            calc1:{
                choiceProduct:"Select product",
                choiceUnitFrom:"Measurement parameter",
                choiceUnitTo:"Convert to what?",
                units:{
                    Gram	:'Gram',
                    Kilogram	:'Kilogram',
                    Ounces	:'Ounces',
                    pounds	:'Pounds',
                    Milliliters	:'Milliliters',
                    Liters	:'Liters',
                    Teaspoon	:'Tea spoon',
                    Tablespoon	:'Tablespoon',
                    Cup	:'Cup',
                    teaspoonEnglish	:'Teaspoon (English)',
                    dessertspoon	:'Dessert spoon',
                    tablespoonEnglish	:'Table spoon (English)',
                    GlassEnglish	:'Glass (English)',
                    OunceUS	:'Ounce (US)',
                    ounceEnglish	:'Ounce (English)',                    
                },
                products:{
                    Wheatflour	:'Wheat flour',
                    Whitesugar	:'White sugar',
                    Water	:'Water',
                    Salt	:'Salt',
                    Milk	:'Milk',                  
                    Powderedsugar	:'Powdered sugar',
                    Soda	:'Soda',
                    Vinegar	:'Vinegar',
                    Butter	:'Butter',
                    Oliveoil	:'Olive oil',
                    Vegetableoil	:'Vegetable oil',
                    Cornflour	:'Corn flour',
                    potatoflour	:'potato flour',
                    milletflour	:'millet flour',
                    Powderedmilk	:'Powdered milk',
                    Almond	:'Almond',
                    Peanut	:'Peanut',
                    Buckwheat	:'Buckwheat',
                    Corn	:'Corn',
                    Barley	:'Barley',
                    barleyperformer	:'barley performer',
                    Beans	:'Beans',
                    Lentils	:'Lentils',
                    Peas	:'Peas',
                    peppercorns	:'peppercorns',
                    Poppy	:'Poppy',
                    Raisin	:'Raisin',
                    Rice	:'Rice',
                    Sago	:'Sago',
                    Semolina	:'Semolina',
                    wheatflakes	:'wheat flakes',
                    Yeast	:'Yeast',
                    Gratedcheese	:'Grated cheese',
                    Cocoa	:'Cocoa',
                    Coffee	:'Coffee',
                    Cinnamon	:'Cinnamon',
                    Creamicecream	:'Cream, ice cream',
                    Eggpowder	:'Egg powder',
                    Gelatin	:'Gelatin',
                    Honey	:'Honey',
                    Hazelnut	:'Hazelnut',
                    Lemonacid	:'Lemon acid',
                    Liquor	:'Liquor',
                    Margarine	:'Margarine',
                    Mayonnaise	:'Mayonnaise',
                    Millet	:'Millet',
                    Cereals	:'Cereals',
                    Oatmeal	:'Oatmeal',
                    Parsley	:'Parsley',     
                    Starch	:'Starch',               
                }
            },
            calc2:{
              category:{
                main_bread_pastries:'Bread, pastries',
                main_flour_products:'Flour products',
                main_cereals:'Cereals',
                main_dairy_products:'Dairy',
                main_vegetables_legumes_nuts:'Vegetables, legumes, nuts',
                main_fruit_with_skin:'Fruit with peel',
                main_berries_with_pit_and_skin:'Berries with pit and skin',
                main_dried_fruits:'Dried fruits',
                main_nuts:'Nuts',
                main_juices:'Juices',
                main_drinks:'Beverages',
                main_sweets:'Sweets'            
              },              
              calc_xe:{
                'xe':'Bread unit (XE)',
                'main_bread_pastries':'Bread, pastries',
                'white_ray_bread_except_butter':'white, gray bread (except butter), g',
                'black_bread':'black bread, g',
                'bran_bread':'bran bread, g',
                'borodinsky_bread':'borodinsky bread, g',
                'crackers':'crackers, g',
                'crackers_dry_biscuits':'crackers (dry biscuits), g',
                'crispbread':'crispbread, g',
                'dry_unsweetened':'dry unsweetened, g',
                'lavash':'lavash, g',
                'salty_sticks':'salty sticks, g',
                'breadcrumbs':'breadcrumbs, g',
                'sweet_bun':'sweet bun, g',
                'main_flour_products':'Flour products',
                'puff_pastry':'puff pastry, g',
                'yeast_dough':'yeast dough, g',
                'pancake_large':'pancake (large), g',
                'pancakes_with_cottage_cheese':'pancakes with cottage cheese',
                'pancakes_with_meat':'pancakes with meat, g',
                'dumplings_with_cottage_cheese':'dumplings with cottage cheese, g',
                'dumplings':'dumplings, g',
                'cheesecake':'cheesecake, g',
                'waffles_small':'waffles (small), g',
                'flour':'flour, g',
                'gingerbread':'gingerbread, g',
                'pancakes_medium':'pancakes (medium), g',
                'butter_cookies':'butter cookies, g',
                'pasta_raw':'pasta (raw), g',
                'pasta_boiled':'pasta (boiled), g',
                'main_cereals':'Cereals',
                'cereals_any_raw':'cereals (any, raw), g',
                'porridge_any':'porridge (any), g',
                'corn_medium':'corn (medium), g',
                'corn_canned':'corn (canned), g',
                'corn_flakes':'corn flakes, g',
                'popcorn':'popcorn, g',
                'oatmeal':'oatmeal, g',
                'wheat_bran':'wheat bran, g',
                'main_dairy_products':'Dairy products',
                'milk':'milk, ml',
                'baked_milk':'baked milk, ml',
                'kefir':'kefir, ml',
                'cream':'cream, ml',
                'buttermilk':'buttermilk, ml',
                'curdled_milk':'curdled milk, ml',
                'fermented_baked_milk':'fermented baked milk, ml',
                'sweet_acidophilus':'sweet acidophilus, ml',
                'yogurt_natural':'yogurt (natural), ml',
                'fruit_yogurt':'fruit yogurt, g',
                'milk_ice_cream_without_glaze_and_waffles':'milk ice cream (without glaze and waffles), g',
                'cream_ice_cream_in_glaze_and_waffles':'cream ice cream (in glaze and waffles), g',
                'cheesecake_medium_with_sugar':'cheesecake (medium, with sugar), g',
                'curd_mass_sweet_without_icing_and_raisins':'curd mass (sweet, without icing and raisins), g',
                'curd_mass_with_raisins_sweet':'curd mass with raisins (sweet), g',
                'condensed_milk_without_sugar':'condensed milk without sugar, g',
                'powdered_milk':'powdered milk, g',
                'main_vegetables_legumes_nuts':'Vegetables, legumes, nuts',
                'raw_and_boiled_potatoes_medium':'raw and boiled potatoes (medium), g',
                'mashed_potatoes':'mashed potatoes, g',
                'fried_potatoes':'fried potatoes, g',
                'french_fries':'french fries, g',
                'potato_pancakes':'potato pancakes, g',
                'chips':'chips, g',
                'carrots_medium':'carrots (medium), g',
                'beets_medium':'beets (medium), g',
                'beans_dried':'beans (dried), g',
                'beans_boiled':'beans (boiled), g',
                'canned_peas':'canned peas, g',
                'peas_fresh':'peas (fresh), g',
                'beans_boiled':'beans (boiled), g',
                'lentils_boiled':'lentils (boiled), g',
                'nuts':'nuts, g',
                'pumpkin':'pumpkin, g',
                'jerusalem_artichoke':'Jerusalem artichoke, g',
                'avocado':'avocado, g',
                'ketchup':'ketchup, g',
                'main_fruit_with_skin':'Fruit (with skin)',
                'apricots':'apricots, g',
                'quince_large':'quince (large), g',
                'pineapple_cross_section':'pineapple (cross section), g',
                'watermelon':'watermelon, g',
                'orange_medium':'orange (medium), g',
                'banana_medium':'banana (medium), g',
                'pomegranate_medium':'pomegranate (medium), g',
                'grapefruit_large':'grapefruit (large), g',
                'pear_small':'pear (small), g',
                'melon':'melon, g',
                'figs':'figs, g',
                'kiwi_large':'kiwi (large), g',
                'lemon':'lemon, g',
                'mango_small':'mango (small), g',
                'tangerines_medium':'tangerines (medium), g',
                'nectarine_medium':'nectarine (medium), g',
                'peach_medium':'peach (medium), g',
                'papaya':'papaya, g',
                'persimmon_medium':'persimmon (medium), g',
                'apple_small':'apple (small), g',
                'main_berries_with_pit_and_skin':'Berries (with pit and skin)',
                'cherry_plum':'cherry plum, g',
                'cranberries':'cranberries, g',
                'grapes_small_berries':'grapes (small berries), g',
                'cherry':'cherry, g',
                'blackberry':'blackberry, g',
                'strawberries_wild_strawberries_medium_sized_berries':'strawberries / wild strawberries (medium-sized berries), g',
                'cranberries':'cranberries, g',
                'gooseberry':'gooseberry, g',
                'raspberry':'raspberry, g',
                'plums_small':'plums (small), g',
                'currant':'currant, g',
                'cherry':'cherry, g',
                'blueberries':'blueberries, g',
                'wild_rose':'wild rose, g',
                'main_dried_fruits':'Dried fruits',
                'bananas':'bananas, g',
                'raisins':'raisins, g',
                'figs':'figs, g',
                'dried_apricots':'dried apricots, g',
                'dates':'dates, g',
                'prunes':'prunes, g',
                'apples':'apples, g',
                'main_nuts':'Nuts',
                'walnuts':'walnuts, g',
                'earthen_with_peel':'earthen with peel, g',
                'earth_peeled':'earth peeled, g',
                'cedar':'cedar, g',
                'cashew':'cashew, g',
                'pistachios':'pistachios, g',
                'hazelnut':'hazelnut, g',
                'main_juices':'Juices',
                'cabbage_juice':'cabbage juice, g',
                'carrot_juice':'carrot juice, g',
                'cucumber_juice':'cucumber juice, g',
                'beetroot_juice':'beetroot juice, g',
                'tomato_juice':'tomato juice, g',
                'orange_juice':'orange juice, g',
                'grape_juice':'grape juice, g',
                'cherry_juice':'cherry juice, g',
                'pear_juice':'pear juice, g',
                'grapefruit_juice':'grapefruit juice, g',
                'redcurrant_juice':'redcurrant juice, g',
                'gooseberry_juice':'gooseberry juice, g',
                'strawberry_juice':'strawberry juice, g',
                'raspberry_juice':'raspberry juice, g',
                'plum_juice':'plum juice, g',
                'apple_juice':'apple juice, g',
                'main_drinks':'Drinks',
                'kvass':'kvass, ml',
                'sparkling_water_sweet':'sparkling water (sweet), ml',
                'kissel':'kissel, ml',
                'compote':'compote, ml',
                'light_beer':'light beer, ml',
                'non_alcoholic':'non-alcoholic, ml',
                'main_sweets':'Sweets',
                'sugar_sand':'sugar (sand), g',
                'sugar_lumpy':'sugar (lumpy), g',
                'fructose':'fructose, g',
                'chocolate_regular':'chocolate, regular',
                'sweetener_chocolate':'sweetener chocolate, g',
                'honey':'honey, g',
                'sugar_jam':'sugar jam, g',
                'chocolate_candy':'chocolate candy, g',
                'pudding':'pudding, g',
                'marmalade':'marmalade, g',
                           
              }
            }
          }
        }
      },
      ru: {
        langName:'Русский',
        translation: {
          description: {
            part1: 'Русский текст для примера',
            part2: 'Learn React',
            calcMenuMainName: 'Рецепты и советы',
            calc1Name: 'Калькулятор веса и объёма',
            calc2Name: 'Калькулятор хлебных единиц',
            calc3Name: 'Калькулятор калорий',

            calc1:{
                choiceProduct:"Выберите продукт",
                choiceUnitFrom:"Параметр измерения",
                choiceUnitTo:"Во что пересчитать?",
                units:{
                    Gram	:'Грамм',
                    Kilogram	:'Килограмм',
                    Ounces	:'Унции',
                    pounds	:'Фунты',
                    Milliliters	:'Миллилитры',
                    Liters	:'Литры',
                    Teaspoon	:'Чайная ложка',
                    Tablespoon	:'Столовая ложка',
                    Cup	:'Стакан',
                    teaspoonEnglish	:'Чайная ложка (англ.)',
                    dessertspoon	:'Десертная ложка (англ.)',
                    tablespoonEnglish	:'Столовая ложка (англ.)',
                    GlassEnglish	:'Стакан (англ.)',
                    OunceUS	:'Унция (США)',
                    ounceEnglish	:'Унция (англ.)',                    
                },
                products:{
                    Wheatflour	:'Мука пшеничная',
                    Whitesugar	:'Сахар белый',
                    Water	:'Вода',
                    Salt	:'Соль',
                    Milk	:'Молоко',                  
                    Powderedsugar	:'Сахар пудра',
                    Soda	:'Сода',
                    Vinegar	:'Уксус',
                    Butter	:'Масло сливочное',
                    Oliveoil	:'Масло оливковое',
                    Vegetableoil	:'Масло растительное',
                    Cornflour	:'Мука кукурузная',
                    potatoflour	:'Мука картофельная',
                    milletflour	:'Мука пшённая',
                    Powderedmilk	:'Молоко сухое',
                    Almond	:'Миндаль',
                    Peanut	:'Арахис',
                    Buckwheat	:'Греча',
                    Corn	:'Кукуруза',
                    Barley	:'Ячмень',
                    barleyperformer	:'Ячмень зерна',
                    Beans	:'Фасоль',
                    Lentils	:'Чечевица',
                    Peas	:'Горох',
                    peppercorns	:'Перец горошек',
                    Poppy	:'Мак',
                    Raisin	:'Изюм',
                    Rice	:'Рис',
                    Sago	:'Саго',
                    Semolina	:'Манная крупа',
                    wheatflakes	:'Хлопья пшеницы',
                    Yeast	:'Дрожжи',
                    Gratedcheese	:'Сыр тертый',
                    Cocoa	:'Какао',
                    Coffee	:'Кофе',
                    Cinnamon	:'Корица',
                    Creamicecream	:'Крем, мороженое',
                    Eggpowder	:'Яичный порошок',
                    Gelatin	:'Желатин',
                    Honey	:'Мёд',
                    Hazelnut	:'Фундук',
                    Lemonacid	:'Лимонная кислота',
                    Liquor	:'Ликер',
                    Margarine	:'Маргарин',
                    Mayonnaise	:'Майонез',
                    Millet	:'Пшено',
                    Cereals	:'Овсяные хлопья',
                    Oatmeal	:'Овсянка',
                    Parsley	:'Петрушка',      
                    Starch	:'Крахмал',              
                }
            },
            calc2:{
              category:{
                main_bread_pastries:'Хлеб, выпечка',
                main_flour_products:'Мучные изделия',
                main_cereals:'Злаки',
                main_dairy_products:'Молочные продукты',
                main_vegetables_legumes_nuts:'Овощи, бобовые орехи',
                main_fruit_with_skin:'Фрукты с кожурой',
                main_berries_with_pit_and_skin:'Ягоды с косточкой и кожицей',
                main_dried_fruits:'Сухофрукты',
                main_nuts:'Орехи',
                main_juices:'Соки',
                main_drinks:'Напитки',
                main_sweets:'Сладости'
              },                  
              calc_xe:{
                'xe':'Хлебная единица (ХЕ)',
                'main_bread_pastries':'Хлеб, выпечка',
                'white_ray_bread_except_butter':'белый, серый хлеб (кроме сдобного), г',
                'black_bread':'чёрный хлеб, г',
                'bran_bread':'хлеб с отрубями, г',
                'borodinsky_bread':'хлеб бородинский, г',
                'crackers':'сухари, г',
                'crackers_dry_biscuits':'крекеры (сухое печенье), г',
                'crispbread':'хрустящие хлебцы, г',
                'dry_unsweetened':'сушки несладкие, г',
                'lavash':'лаваш, г',
                'salty_sticks':'соленые палочки, г',
                'breadcrumbs':'панировочные сухари, г',
                'sweet_bun':'сдобная булка, г',
                'main_flour_products':'Изделия из муки',
                'puff_pastry':'тесто слоеное, г',
                'yeast_dough':'тесто дрожевое, г',
                'pancake_large':'блин (большой), г',
                'pancakes_with_cottage_cheese':'блинчики с творогом, г',
                'pancakes_with_meat':'блинчики с мясом, г',
                'dumplings_with_cottage_cheese':'вареники с творогом , г',
                'dumplings':'пельмени , г',
                'cheesecake':'ватрушка, г',
                'waffles_small':'вафли (мелкие), г',
                'flour':'мука, г',
                'gingerbread':'пряник, г',
                'pancakes_medium':'оладьи (средние), г',
                'butter_cookies':'печенье сливочное, г',
                'pasta_raw':'макаронные изделия (в сыром виде), г',
                'pasta_boiled':'макаронные изделия (в вареном виде), г',
                'main_cereals':'Зерновые, злаковые, каши',
                'cereals_any_raw':'крупа (любая, в сыром виде), г',
                'porridge_any':'каша (любая), г',
                'corn_medium':'кукуруза (средняя), г',
                'corn_canned':'кукуруза (консервированная), г',
                'corn_flakes':'кукурузные хлопья, г',
                'popcorn':'попкорн, г',
                'oatmeal':'овсяные хлопья, г',
                'wheat_bran':'пшеничные отруби, г',
                'main_dairy_products':'Молочные продукты',
                'milk':'молоко, мл',
                'baked_milk':'молоко топленое, мл',
                'kefir':'кефир, мл',
                'cream':'сливки, мл',
                'buttermilk':'пахта, мл',
                'curdled_milk':'простокваша, мл',
                'fermented_baked_milk':'ряженка, мл',
                'sweet_acidophilus':'ацидофилин сладкий, мл',
                'yogurt_natural':'йогурт (натуральный), мл',
                'fruit_yogurt':'йогурт фруктовый, г',
                'milk_ice_cream_without_glaze_and_waffles':'мороженое молочное (без глазури и вафель), г',
                'cream_ice_cream_in_glaze_and_waffles':'мороженое сливочное (в глазури и вафлях), г',
                'cheesecake_medium_with_sugar':'сырник (средний, с сахаром), г',
                'curd_mass_sweet_without_icing_and_raisins':'творожная масса (сладкая, без глазури и изюма), г',
                'curd_mass_with_raisins_sweet':'творожная масса с изюмом (сладкая), г',
                'condensed_milk_without_sugar':'сгущенное молоко без сахара, г',
                'powdered_milk':'сухое молоко в порошке, г',
                'main_vegetables_legumes_nuts':'Овощи, бобовые, орехи',
                'raw_and_boiled_potatoes_medium':'сырой и вареный картофель (средний), г',
                'mashed_potatoes':'картофельное пюре, г',
                'fried_potatoes':'жареный картофель, г',
                'french_fries':'картофель фри, г',
                'potato_pancakes':'оладьи из картофеля, г',
                'chips':'чипсы, г',
                'carrots_medium':'морковь (средняя), г',
                'beets_medium':'свёкла (средняя), г',
                'beans_dried':'бобы (сушеные), г',
                'beans_boiled':'бобы (вареные), г',
                'canned_peas':'горошек консервированный, г',
                'peas_fresh':'горох (свежий), г',
                'beans_boiled':'фасоль (вареная), г',
                'lentils_boiled':'чечевица (вареная), г',
                'nuts':'орехи, г',
                'pumpkin':'тыква, г',
                'jerusalem_artichoke':'топинамбур, г',
                'avocado':'авакадо, г',
                'ketchup':'кетчуп, г',
                'main_fruit_with_skin':'Фрукты (с кожурой)',
                'apricots':'абрикосы, г',
                'quince_large':'айва (крупная), г',
                'pineapple_cross_section':'ананас (поперечный срез), г',
                'watermelon':'арбуз, г',
                'orange_medium':'апельсин (средний), г',
                'banana_medium':'банан (средний), г',
                'pomegranate_medium':'гранат (средний), г',
                'grapefruit_large':'грейпфрут (крупный), г',
                'pear_small':'груша (маленькая), г',
                'melon':'дыня, г',
                'figs':'инжир, г',
                'kiwi_large':'киви (крупный), г',
                'lemon':'лимон, г',
                'mango_small':'манго (небольшой), г',
                'tangerines_medium':'мандарины (средние), г',
                'nectarine_medium':'нектарин (средний), г',
                'peach_medium':'персик (средний), г',
                'papaya':'папайя, г',
                'persimmon_medium':'хурма (средняя), г',
                'apple_small':'яблоко (маленькое), г',
                'main_berries_with_pit_and_skin':'Ягоды (с косточкой и кожурой)',
                'cherry_plum':'алыча, г',
                'cranberries':'брусника, г',
                'grapes_small_berries':'виноград (небольшие ягоды), г',
                'cherry':'вишня, г',
                'blackberry':'ежевика, г',
                'strawberries_wild_strawberries_medium_sized_berries':'клубника / земляника (ягоды среднего размера), г',
                'cranberries':'клюква, г',
                'gooseberry':'крыжовник, г',
                'raspberry':'малина, г',
                'plums_small':'сливы (небольшие), г',
                'currant':'смородина, г',
                'cherry':'черешня, г',
                'blueberries':'черника, г',
                'wild_rose':'шиповник, г',
                'main_dried_fruits':'Сухофрукты',
                'bananas':'бананы, г',
                'raisins':'изюм, г',
                'figs':'инжир, г',
                'dried_apricots':'курага, г',
                'dates':'финики, г',
                'prunes':'чернослив, г',
                'apples':'яблоки, г',
                'main_nuts':'Орехи',
                'walnuts':'грецкие, г',
                'earthen_with_peel':'земляные с кожурой, г',
                'earth_peeled':'земляные очищенные, г',
                'cedar':'кедровые, г',
                'cashew':'кешью, г',
                'pistachios':'фисташки, г',
                'hazelnut':'фундук, г',
                'main_juices':'Соки',
                'cabbage_juice':'сок капустный, г',
                'carrot_juice':'сок морковный, г',
                'cucumber_juice':'сок огуречный, г',
                'beetroot_juice':'сок свекольный, г',
                'tomato_juice':'сок томатный, г',
                'orange_juice':'сок апельсиновый, г',
                'grape_juice':'сок виноградный, г',
                'cherry_juice':'сок вишневый, г',
                'pear_juice':'сок грушевый, г',
                'grapefruit_juice':'сок грейпфрутовый, г',
                'redcurrant_juice':'сок красносмородиновый, г',
                'gooseberry_juice':'сок крыжовниковый, г',
                'strawberry_juice':'сок клубничный, г',
                'raspberry_juice':'сок малиновый, г',
                'plum_juice':'сок сливовый, г',
                'apple_juice':'сок яблочный, г',
                'main_drinks':'Напитки',
                'kvass':'квас, мл',
                'sparkling_water_sweet':'газированная вода (сладкая), мл',
                'kissel':'кисель, мл',
                'compote':'компот, мл',
                'light_beer':'пиво светлое, мл',
                'non_alcoholic':'безалкогольное, мл',
                'main_sweets':'Сладости',
                'sugar_sand':'сахар (песок), г',
                'sugar_lumpy':'сахар (кусковой), г',
                'fructose':'фруктоза, г',
                'chocolate_regular':'шоколад обычный, г',
                'sweetener_chocolate':'шоколад на сахарозаменителе, г',
                'honey':'мед, г',
                'sugar_jam':'варенье на сахаре, г',
                'chocolate_candy':'конфета шоколадная, г',
                'pudding':'пудинг, г',
                'marmalade':'мармелад, г',
                
              }              
            }
          }
        }
      },
      es: {
        langName:'Espan',
        translation: {
          description: {
            part1: 'texto de ejemplo en español',
            part2: 'OLA OLA OLA',
            calc1Name: 'Calculadora de peso y volumen',
            calc2Name: 'Calculadora de unidades de pan',
            calc3Name: 'calculadora de calorias',

            calc1:{
                choiceProduct:"Seleccionar producto",
                choiceUnitFrom:"Parámetro de medición",
                choiceUnitTo:"¿Convertir a qué?",
                units:{
                    Gram	:'Gramo',
                    Kilogram	:'Kilogramo',
                    Ounces	:'Onzas',
                    pounds	:'libras',
                    Milliliters	:'Mililitros',
                    Liters	:'Litros',
                    Teaspoon	:'Cuchara de te',
                    Tablespoon	:'Cucharada',
                    Cup	:'Taza',
                    teaspoonEnglish	:'cucharadita (inglés)',
                    dessertspoon	:'cuchara para postres',
                    tablespoonEnglish	:'cuchara de mesa (inglés)',
                    GlassEnglish	:'Vidrio (inglés)',
                    OunceUS	:'Onza (EE. UU.)',
                    ounceEnglish	:'onza (inglés)',
                },
                products:{
                    Wheatflour	:'Harina de trigo',
                    Whitesugar	:'azucar blanca',
                    Water	:'Agua',
                    Salt	:'Sal',
                    Milk	:'Leche',                  
                    Powderedsugar	:'Azúcar en polvo',
                    Soda	:'soda',
                    Vinegar	:'Vinagre',
                    Butter	:'Manteca',
                    Oliveoil	:'Aceite de oliva',
                    Vegetableoil	:'Aceite vegetal',
                    Cornflour	:'Harina de maíz',
                    potatoflour	:'harina de papa',
                    milletflour	:'harina de mijo',
                    Powderedmilk	:'Leche en polvo',
                    Almond	:'Almendra',
                    Peanut	:'Maní',
                    Buckwheat	:'Alforfón',
                    Corn	:'Maíz',
                    Barley	:'Cebada',
                    barleyperformer	:'intérprete de cebada',
                    Beans	:'Frijoles',
                    Lentils	:'lentejas',
                    Peas	:'Guisantes',
                    peppercorns	:'granos de pimienta',
                    Poppy	:'Amapola',
                    Raisin	:'Pasa',
                    Rice	:'Arroz',
                    Sago	:'Sagú',
                    Semolina	:'Sémola',
                    wheatflakes	:'copos de trigo',
                    Yeast	:'Levadura',
                    Gratedcheese	:'Queso rallado',
                    Cocoa	:'Cacao',
                    Coffee	:'Café',
                    Cinnamon	:'Canela',
                    Creamicecream	:'Crema, helado',
                    Eggpowder	:'huevo en polvo',
                    Gelatin	:'Gelatina',
                    Honey	:'Miel',
                    Hazelnut	:'Avellana',
                    Lemonacid	:'Ácido de limón',
                    Liquor	:'Espíritu',
                    Margarine	:'Margarina',
                    Mayonnaise	:'Mayonesa',
                    Millet	:'Mijo',
                    Cereals	:'Cereales',
                    Oatmeal	:'Avena',
                    Parsley	:'Perejil',       
                    Starch	:'Almidón',             
                }
            },
            calc2:{
              category:{
                main_bread_pastries:'pan, pasteles',
                main_flour_products:'Productos de harina',
                main_cereals:'Cereales',
                main_dairy_products:'Lácteos',
                main_vegetables_legumes_nuts:'Verduras, legumbres, frutos secos.',
                main_fruit_with_skin:'fruta con piel',
                main_berries_with_pit_and_skin:'Bayas con hueso y piel.',
                main_dried_fruits:'Frutas secas',
                main_nuts:'Nueces',
                main_juices:'Jugos',
                main_drinks:'Bebidas',
                main_sweets:'dulces'                
              },
              calc_xe:{
                'xe':'Unidad de pan (XE)',
                'main_bread_pastries':'Pan, bollería',
                'white_ray_bread_except_butter':'pan blanco, gris (excepto mantequilla), g',
                'black_bread':'pan negro, g',
                'bran_bread':'pan de salvado, g',
                'borodinsky_bread':'pan borodinsky, g',
                'crackers':'galletas saladas',
                'crackers_dry_biscuits':'galletas saladas (galletas secas), g',
                'crispbread':'pan crujiente, g',
                'dry_unsweetened':'seco sin azúcar, g',
                'lavash':'lavash, g',
                'salty_sticks':'palitos salados, g',
                'breadcrumbs':'pan rallado, gramo',
                'sweet_bun':'bollo dulce, g',
                'main_flour_products':'Productos de harina',
                'puff_pastry':'hojaldre, g',
                'yeast_dough':'masa de levadura, g',
                'pancake_large':'panqueque (grande), g',
                'pancakes_with_cottage_cheese':'panqueques con requesón',
                'pancakes_with_meat':'panqueques con carne, g',
                'dumplings_with_cottage_cheese':'albóndigas con requesón, g',
                'dumplings':'albóndigas, g',
                'cheesecake':'tarta de queso',
                'waffles_small':'gofres (pequeños), g',
                'flour':'harina, gramo',
                'gingerbread':'pan de jengibre, gramo',
                'pancakes_medium':'tortitas (medianas), g',
                'butter_cookies':'galletas de mantequilla, g',
                'pasta_raw':'pasta (cruda), g',
                'pasta_boiled':'pasta (hervida), g',
                'main_cereals':'Cereales',
                'cereals_any_raw':'cereales (cualquiera, crudos), g',
                'porridge_any':'papilla (cualquiera), g',
                'corn_medium':'maíz (medio), g',
                'corn_canned':'maíz (enlatado), g',
                'corn_flakes':'hojuelas de maíz, g',
                'popcorn':'palomitas de maiz',
                'oatmeal':'avena, gramos',
                'wheat_bran':'salvado de trigo, g',
                'main_dairy_products':'Productos lácteos',
                'milk':'leche',
                'baked_milk':'leche horneada, ml',
                'kefir':'kéfir, ml',
                'cream':'crema, mililitros',
                'buttermilk':'suero de leche, ml',
                'curdled_milk':'leche cuajada, ml',
                'fermented_baked_milk':'leche horneada fermentada, ml',
                'sweet_acidophilus':'acidófilo dulce, ml',
                'yogurt_natural':'yogur (natural), ml',
                'fruit_yogurt':'yogur de frutas, g',
                'milk_ice_cream_without_glaze_and_waffles':'helado de leche (sin glaseado y gofres), g',
                'cream_ice_cream_in_glaze_and_waffles':'helado de nata (en glaseado y gofres), g',
                'cheesecake_medium_with_sugar':'tarta de queso (mediana, con azúcar), g',
                'curd_mass_sweet_without_icing_and_raisins':'masa de cuajada (dulce, sin glaseado y pasas), g',
                'curd_mass_with_raisins_sweet':'masa de cuajada con pasas (dulce), g',
                'condensed_milk_without_sugar':'leche condensada sin azúcar, g',
                'powdered_milk':'leche en polvo, g',
                'main_vegetables_legumes_nuts':'Verduras, legumbres, frutos secos',
                'raw_and_boiled_potatoes_medium':'papas crudas y hervidas (medianas), g',
                'mashed_potatoes':'puré de patatas, g',
                'fried_potatoes':'papas fritas, g',
                'french_fries':'papas fritas, g',
                'potato_pancakes':'tortitas de patata, g',
                'chips':'papas fritas',
                'carrots_medium':'zanahorias (medianas), g',
                'beets_medium':'remolachas (medianas), g',
                'beans_dried':'frijoles (secos), g',
                'beans_boiled':'frijoles (hervidos), g',
                'canned_peas':'guisantes enlatados, g',
                'peas_fresh':'guisantes (frescos), g',
                'beans_boiled':'frijoles (hervidos), g',
                'lentils_boiled':'lentejas (hervidas), g',
                'nuts':'nueces',
                'pumpkin':'calabaza',
                'jerusalem_artichoke':'alcachofa de Jerusalén, g',
                'avocado':'aguacate',
                'ketchup':'salsa de tomate',
                'main_fruit_with_skin':'Fruta (con piel)',
                'apricots':'albaricoques, g',
                'quince_large':'membrillo (grande), g',
                'pineapple_cross_section':'piña (sección transversal), g',
                'watermelon':'sandía, g',
                'orange_medium':'naranja (mediana), g',
                'banana_medium':'plátano (mediano), g',
                'pomegranate_medium':'granada (mediana), g',
                'grapefruit_large':'toronja (grande), g',
                'pear_small':'pera (pequeña), g',
                'melon':'melón',
                'figs':'higos, g',
                'kiwi_large':'kiwi (grande), g',
                'lemon':'limón, gramo',
                'mango_small':'mango (pequeño), g',
                'tangerines_medium':'mandarinas (medianas), g',
                'nectarine_medium':'nectarina (mediana), g',
                'peach_medium':'durazno (mediano), g',
                'papaya':'papaya',
                'persimmon_medium':'caqui (mediano), g',
                'apple_small':'manzana (pequeña), g',
                'main_berries_with_pit_and_skin':'Bayas (con carozo y piel)',
                'cherry_plum':'ciruela cereza, g',
                'cranberries':'arándanos, g',
                'grapes_small_berries':'uvas (bayas pequeñas), g',
                'cherry':'cereza',
                'blackberry':'mora, g',
                'strawberries_wild_strawberries_medium_sized_berries':'fresas / fresas silvestres (bayas medianas), g',
                'cranberries':'arándanos, g',
                'gooseberry':'grosella, g',
                'raspberry':'frambuesa, gramo',
                'plums_small':'ciruelas (pequeñas), g',
                'currant':'grosella',
                'cherry':'cereza',
                'blueberries':'arándanos, g',
                'wild_rose':'rosa salvaje, g',
                'main_dried_fruits':'Frutos secos',
                'bananas':'plátanos, gramo',
                'raisins':'pasas, g',
                'figs':'higos, g',
                'dried_apricots':'albaricoques secos, g',
                'dates':'fechas',
                'prunes':'ciruelas pasas',
                'apples':'manzanas',
                'main_nuts':'Nueces',
                'walnuts':'nueces, gramo',
                'earthen_with_peel':'barro con cáscara, g',
                'earth_peeled':'tierra pelada, g',
                'cedar':'cedro, g',
                'cashew':'marañón, g',
                'pistachios':'pistachos, g',
                'hazelnut':'avellana, g',
                'main_juices':'Jugos',
                'cabbage_juice':'jugo de repollo, g',
                'carrot_juice':'jugo de zanahoria, g',
                'cucumber_juice':'jugo de pepino, g',
                'beetroot_juice':'jugo de remolacha, g',
                'tomato_juice':'jugo de tomate, g',
                'orange_juice':'jugo de naranja, gramo',
                'grape_juice':'jugo de uva, g',
                'cherry_juice':'jugo de cereza, g',
                'pear_juice':'jugo de pera, g',
                'grapefruit_juice':'jugo de toronja, g',
                'redcurrant_juice':'jugo de grosella roja, g',
                'gooseberry_juice':'jugo de grosella, g',
                'strawberry_juice':'jugo de fresa, g',
                'raspberry_juice':'jugo de frambuesa, g',
                'plum_juice':'jugo de ciruela, g',
                'apple_juice':'jugo de manzana, gramo',
                'main_drinks':'Bebidas',
                'kvass':'kvas, ml',
                'sparkling_water_sweet':'agua con gas (dulce), ml',
                'kissel':'beso, ml',
                'compote':'compota, ml',
                'light_beer':'cerveza ligera, ml',
                'non_alcoholic':'sin alcohol, ml',
                'main_sweets':'Dulces',
                'sugar_sand':'azúcar (arena), g',
                'sugar_lumpy':'azúcar (en grumos), g',
                'fructose':'fructosa, g',
                'chocolate_regular':'chocolatada, normal',
                'sweetener_chocolate':'edulcorante de chocolate, g',
                'honey':'cariño',
                'sugar_jam':'mermelada de azúcar, g',
                'chocolate_candy':'caramelo de chocolate, g',
                'pudding':'budín',
                'marmalade':'mermelada',
                           
              }
            }
          }
        }
      }      
    }
  });

export default i18n;