import React from 'react';
import { useTranslation } from 'react-i18next';

const Calc2 = () => {
    const { t, i18n } = useTranslation();

    return (
        <>
            {t('description.calc3Name')}
        </>
    );
}

export default Calc2;