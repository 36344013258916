import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation, Trans } from 'react-i18next';

import MobileMain from './Mobile/Main';
import DesktopMain from './Desktop/Main';

function App() {
  const { t } = useTranslation();


  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })  // You are a desktop or laptop
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })       // You  have a huge screen
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })  // You are a tablet or mobile phone
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })    // Your are in portrait or landscape orientation
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })      // You are retina
  const isWatch = useMediaQuery({ query: '(max-width: 224px)' })  // You are a tablet or mobile phone


  if(isWatch) 
  {
    return (
      <>
        Попробуйте другое устройство!        
      </>
    );
  }else if(isTabletOrMobile){
    return (
      <>

        <MobileMain />

      </>
    );    
  }else{
    return (
      <>
      <div style={{width:"800px", left:"50%", top:"0", position:"absolute",marginRight:"50%", transform:"translate(-50%, 0)"}}>
        <MobileMain />
      </div>
      </>
    );     
  }
}

export default App;
