import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import { useState, useEffect } from 'react';

import { Routes, Route, Navigate, useNavigate  } from "react-router-dom";
import Calc1 from './Calc1';
import Calc2 from './Calc2';
import Calc3 from './Calc3';
import Recipe from './Recipe';


const Main = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [activeScreen, setActiveScreen] = useState('');
    

const langu = ['ru', 'en', 'es']

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })  // You are a desktop or laptop
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })       // You  have a huge screen
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })  // You are a tablet or mobile phone
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })    // Your are in portrait or landscape orientation
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })      // You are retina
  const isWatch = useMediaQuery({ query: '(max-width: 224px)' })  // You are a tablet or mobile phone

  useEffect(()=>{
    const url_parts = window.location.pathname.split('/');
    if(url_parts.length>2){
      setActiveScreen(url_parts[2]);
    }else{
      setActiveScreen('');
    }

console.log(url_parts[2]);
console.log(url_parts);
  },[]);

  const handleGoTo = (uri) => {
    setActiveScreen(uri);
    navigate(`/${i18n.language}/${uri}`);
  }

  return (
    <>
        <div style={{ width:"100%", display:"inline-block"}}>
            <span style={{float:"right", alignItems:"center", display:"flex"}} >
            <img src={`/images/icon_${i18n.language}.png`} onClick={(e)=>{}}/>
                <select name="lang" defaultValue={i18n.language} onChange={(e)=>{setActiveScreen(''); i18n.changeLanguage(e.target.value)}}>
                    {langu.map((lang, index)=>(
                      <option key={index} value={lang} >{i18n.getDataByLanguage(lang).langName}</option>
                    ))}
                </select>
            </span>
        </div>

        <div style={{height:"150px", width:`${(window.innerWidth>800)?800:window.innerWidth}px`, display:"inline-block", backgroundImage:`url("/images/logo_header_small_1.png")`,
                      backgroundRepeat:"repeat"}}>
      

        </div>  



        <div style={{backgroundColor:"#fff", overflow:"hidden", overflowX:"auto", overflowY:"hidden"}}>
            <span style={{...styleSpanMenu, background:`${(activeScreen==='')?'#bacbe8':'#D9D9D9'}`,color:`${(activeScreen==='calc1')?'#000000':'#000000'}`}} 
                    onClick={()=>{handleGoTo('')}}>{t('description.calcMenuMainName')} 
            </span>           
            <span style={{...styleSpanMenu, background:`${(activeScreen==='calc1')?'#bacbe8':'#D9D9D9'}`,color:`${(activeScreen==='calc1')?'#000000':'#000000'}`}} 
                    onClick={()=>{handleGoTo('calc1')}}>{t('description.calc1Name')} 
            </span> 
            <span style={{...styleSpanMenu, background:`${(activeScreen==='calc2')?'#bacbe8':'#D9D9D9'}`,color:`${(activeScreen==='calc2')?'#000000':'#000000'}`}} 
                    onClick={()=>{handleGoTo('calc2')}}>{t('description.calc2Name')} 
            </span>
            <span style={{...styleSpanMenu, background:`${(activeScreen==='calc3')?'#bacbe8':'#D9D9D9'}`,color:`${(activeScreen==='calc3')?'#000000':'#000000'}`}} 
                    onClick={()=>{handleGoTo('calc3')}}>{t('description.calc3Name')} 
            </span>
        </div>


    <div style={{width:"100%"}}>
    <Routes>
         <Route path={`/${i18n.language}/`}>
          <Route path='' element={ <Recipe /> } />
          <Route path='calc1' element={<Calc1 />} />
          <Route path='calc2' element={<Calc2 />} />
          <Route path='calc3' element={<Calc3 />} />

         </Route>
         <Route path="*" element={ <Navigate replace to={`/${i18n.language}`} /> } />

  </Routes>
    </div>

<br />

    </>
  );
}

const styleSpanMenu = {
//    background: "#771CBF", 
//    color:"#FFFFFF",
//    border: "1px solid #000000",
//    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    cursor:"pointer",
    borderRadius: "15px",
    height:"30px",
    display:"inline-flex",
    justifyContent:"center",
    paddingLeft:"15px",
    paddingRight:"15px",
    margin:"5px",
    alignItems:"center"
}

export default Main;
