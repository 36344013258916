import { use } from 'i18next';
import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Calc1 = () => {
    const { t, i18n } = useTranslation();

    const [Products, setProducts] = useState([]);
    const [Units, setUnits] = useState([]);
    const [selectProduct, setSelectProduct] = useState('');
    const [selectUnitFrom, setSelectUnitFrom] = useState('');
    const [selectUnitTo, setSelectUnitTo] = useState(''); 
    const [total, setTotal] = useState(0);
    const [val, setVal] = useState(0);

  //  console.log(navigator);
    useEffect(()=>{
    //   const tmp = t('description.calc1.products', {count: 100});
        const products = []
        for (const [key, value] of Object.entries(i18n.store.data.en.translation.description.calc1.products)) {
       //     console.log(key, value);
            let p = {}
            p[key] = t(`description.calc1.products.${key}`);//value;
            products.push(p);
        }
        products.sort((a,b)=>(Object.values(a)[0].localeCompare(Object.values(b)[0])));
        setProducts(products);

        const units = []
        for (const [key, value] of Object.entries(i18n.store.data.en.translation.description.calc1.units)) {
       //     console.log(key, value);
            let p = {}
            p[key] = t(`description.calc1.units.${key}`);//value;
            units.push(p);
        }
        units.sort((a,b)=>(Object.values(a)[0].localeCompare(Object.values(b)[0])));
        setUnits(units);



    },[]);
 
    useEffect(()=>{
        if(selectProduct === ''){
            setSelectUnitFrom('');
        }
    },[selectProduct])

    useEffect(()=>{
        if(selectUnitFrom === ''){
            setSelectUnitTo('');
        }
    },[selectUnitFrom])



    useEffect(()=>{

        var r = 0;
        if(unitConst[selectUnitFrom] !== undefined && unitConst[selectUnitTo] !== undefined && selectProduct !== '' && pV[selectProduct] !== undefined){
   //         console.log(unitConst[selectUnitFrom].type);
   //         console.log(unitConst[selectUnitTo].type);

            if(unitConst[selectUnitFrom].type === 'VOLUME' && unitConst[selectUnitTo].type === 'WEIGHT'){
                r = pV[selectProduct] * unitConst[selectUnitFrom].k * val;
                r = r / unitConst[selectUnitTo].k;
            }

            if(unitConst[selectUnitFrom].type === 'WEIGHT' && unitConst[selectUnitTo].type === 'VOLUME'){
                r = (unitConst[selectUnitFrom].k * val) / pV[selectProduct];
                r = r / unitConst[selectUnitTo].k;
            }

            if(unitConst[selectUnitFrom].type === 'WEIGHT' && unitConst[selectUnitTo].type === 'WEIGHT'){
                r = val * unitConst[selectUnitFrom].k;
                r = r / unitConst[selectUnitTo].k ;
            }

            if(unitConst[selectUnitFrom].type === 'VOLUME' && unitConst[selectUnitTo].type === 'VOLUME'){
                r = val * unitConst[selectUnitFrom].k;
                r = r / unitConst[selectUnitTo].k ;
            }            

        }
/*
        console.log("selectUnitTo " +selectUnitTo);
        console.log("selectUnitFrom " +selectUnitFrom);
        console.log("selectProduct " +selectProduct);
        console.log("val " +val);
        */
        setTotal(r.toFixed(2));
    },[selectUnitTo, selectUnitFrom, selectProduct, val]);



    return ( 
        <>

            <div style={{backgroundColor:"#bacbe8", borderRadius: "15px"}}>

                <div style={{justifyContent:"center", display:"flex"}}>
                                <span style={{backgroundColor:"#F5F5F5", 
                                              borderRadius: "15px",
                                              marginTop:"15px",
                                              marginBottom:"15px",
                                              paddingTop:"5px",
                                              paddingBottom:"5px",
                                              width:"80%"}}>
                                                <select style={style_select_1} 
                                                        onChange={(e)=>{setSelectProduct(e.target.value)}}>
                                                   <option value="">{t('description.calc1.choiceProduct')}</option>
                                                    {Products.map((prod, index)=>(
                                                        <option key={index} value={Object.keys(prod)[0]}>{Object.values(prod)[0]}</option>
                                                    ))}

                                                </select>
                                </span>

                </div>


                <div style={{justifyContent:"center", display:"flex"}}>
                    <span style={{width:"80%", display:"flex", marginBottom:"15px",marginTop:"15px"}}>


                    <span style={{backgroundColor:"#F5F5F5", 
                                              borderRadius: "15px",
                                              border:"0px",
                                              marginTop:"0px",
                                              marginBottom:"0px",
                                              marginLeft:"0px",
                                              marginRight:"5px",
                                              padding:"5px",
                                              width:"30%"}}>
                        <input type="number" style={{width:"90%", border:"0px", outline:"none"}}  value={val} onChange={(e)=>{setVal(e.target.value)}}/>
                    </span>
                    <span style={{backgroundColor:"#F5F5F5", 
                                              borderRadius: "15px",
                                              marginTop:"0px",
                                              marginBottom:"0px",
                                              marginLeft:"5px",
                                              marginRight:"0px",
                                              padding:"5px",
                                              width:"70%"}}>
                                    <select style={style_select_1} 
                                                        onChange={(e)=>{setSelectUnitFrom(e.target.value)}}>
                                                   <option value="">{t('description.calc1.choiceUnitFrom')}</option>
                                                    {Units.map((unit, index)=>(
                                                        <option key={index} value={Object.keys(unit)[0]}>{Object.values(unit)[0]}</option>
                                                    ))}

                                                </select>
                    </span>       
                    </span>              
                </div>

                {(selectUnitFrom!=='' || true)?(
                    <>
                
                <div style={{justifyContent:"center", display:"flex"}}>
                                <span style={{backgroundColor:"#F5F5F5", 
                                              borderRadius: "15px",
                                              marginTop:"15px",
                                              marginBottom:"15px",
                                              paddingTop:"5px",
                                              paddingBottom:"5px",
                                              width:"80%"}}>
                                                <select style={style_select_1} 
                                                        onChange={(e)=>{setSelectUnitTo(e.target.value)}}>
                                                   <option value="">{t('description.calc1.choiceUnitTo')}</option>
                                                    {Units.map((unit, index)=>(
                                                        <option key={index} value={Object.keys(unit)[0]}>{Object.values(unit)[0]}</option>
                                                    ))}

                                                </select>
                                </span>

                </div>                        
                

                <div style={{justifyContent:"center", display:"flex"}}>
                <span style={{backgroundColor:"#F5F5F5", 
                                              display:"flex",
                                              justifyContent:"center",
                                              borderRadius: "20px",
                                              height: "80px",
                                              border:"0px",
                                              marginTop:"0px",
                                              marginBottom:"10px",
                                              marginLeft:"0px",
                                              marginRight:"5px",
                                              padding:"5px",
                                              fontSize:"80px",
                                              width:"80%"}}>
                        {total}
                    </span>
                </div>
                    </>
                ):(<></>)}

            </div>

          
        </>
    );
}


const style_select_1 = {boxShadow:"none", outline:"none",backgroundColor:"transparent", border:"0px", width:"100%"}


const unitConst = {
    'Gram' :      {'type':'WEIGHT', 'k' : 1},//'Грамм',
    'Kilogram' :  {'type':'WEIGHT', 'k' : 1000},//'Килограмм',
    'Ounces' :    {'type':'WEIGHT', 'k' : 28.3494925440834},//'Унции',
    'pounds' :    {'type':'WEIGHT', 'k' : 453.592},//'Фунты',
    'Milliliters' :  {'type':'VOLUME', 'k' : 1},//'Миллилитры',
    'Liters' :       {'type':'VOLUME', 'k' : 1000},//'Литры',
    'Teaspoon' :     {'type':'VOLUME', 'k' : 4.93},//'Чайная ложка',
    'Tablespoon' :   {'type':'VOLUME', 'k' : 14.79},//'Столовая ложка',
    'Cup' :          {'type':'VOLUME', 'k' : 236.59},//'Стакан',
    'teaspoonEnglish' :    {'type':'VOLUME', 'k' : 5},//'Чайная ложка (англ.)',
    'dessertspoon' :       {'type':'VOLUME', 'k' : 10},//'Десертная ложка (англ.)',
    'tablespoonEnglish' :  {'type':'VOLUME', 'k' : 15},//'Столовая ложка (англ.)',
    'GlassEnglish' :       {'type':'VOLUME', 'k' : 285.000},//'Стакан (англ.)',
    'OunceUS' :            {'type':'WEIGHT', 'k' : 29.573549417401},//'Унция (США)',
    'ounceEnglish' :       {'type':'WEIGHT', 'k' : 28.4131268646114}//'Унция (англ.)'	
};

     


const pV = {
    'Wheatflour' : 0.71042616, //' Мука пшеничная',
    'Whitesugar' : 0.8438818565400844, // Сахар белый',
    'Water' : 1, //Вода',
    'Salt' : 1.265822784810127, // Соль',
    'Milk' : 1.03, // Молоко',
    'Powderedsugar' : 0.5063291139240506, //  'Сахар пудра',
    'Soda' : 1.555555901234645, //  'Сода',
    'Vinegar' :  1.0492, // 'Уксус',
    'Butter' :  1.012145748987854, //'Масло сливочное',
    'Oliveoil' :  0.911390097746588, //'Масло оливковое',
    'Vegetableoil' :  0.9198303464908932, //'Масло растительное',
    'Cornflour' :  0.64, //'Мука кукурузное',
    'potatoflour' :  0.7199999424000046, //'Мука картофельная', 
    'milletflour' :  0.5063291139240506, //'Мука пшённая',
    'Powderedmilk' :  0.4800000768000123, //'Молоко сухое',
    'Almond' :  0.64, //'Миндаль',
    'Peanut' :  0.700000210000063, //'Арахис',
    'Buckwheat' :  0.8400001344000215, //'Греча',
    'Corn' :  0.2, //'Кукуруза',
    'Barley' :  0.7199999424000046, //'Ячмень',
    'barleyperformer' :  0.9199995952001781, //'Ячмень зерна',
    'Beans' :  0.879786036036036, //'Фасоль',
    'Lentils' :  0.8400001344000215, //'Чечевица',
    'Peas' :  0.9199995952001781, //'Горох',
    'peppercorns' :  1, //'Перец горошек',
    'Poppy' :  0.6200000868000122, //'Мак',
    'Raisin' :  0.7600002736000985, //'Изюм',
    'Rice' :  0.8016880343250749, //'Рис',
    'Sago' :  0.7199999424000046, //'Саго',
    'Semolina' :  0.8, //'Манная крупа',
    'wheatflakes' :  0.2399999808000015, //'Хлопья пшеницы',
    'Yeast' :  0.9451474240952104, //'Дрожжи',
    'Gratedcheese' :  0.379746739304623, //'Сыр тертый',
    'Cocoa' :  1.388888888888889, //'Какао',
    'Coffee' :  1.111111111111111, //'Кофе',
    'Cinnamon' :  1.111111111111111, //'Корица',
    'Creamicecream' :  0.7795957951721192, //'Крем, мороженое',
    'Eggpowder' :  0.4, //'Яичный порошок',
    'Gelatin' :  0.8333333333333333, //'Желатин',
    'Honey' :  1.299999610000117, //'Мёд',
    'Hazelnut' :  0.6800001088000174, //'Фундук',
    'Lemonacid' :  1.388888888888889, //'Лимонная кислота',
    'Liquor' :  1.111111111111111, //'Ликер',
    'Margarine' :  0.8333333333333333, //'Маргарин',
    'Mayonnaise' :  0.8333333333333333, //'Майонез',
    'Millet' :  0.8799997184000901, //'Пшено',
    'Cereals' :  0.4, //'Овсяные хлопья',
    'Oatmeal' :  0.279999966400004, //'Овсянка',
    'Parsley' :  0.9199995952001781, //'Петрушка',	
    'Starch' :  1.5 					//'Крахмал',	

}; 

/*
  
*/

export default Calc1;