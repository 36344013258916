import React from 'react';

const Recipe = () => {

    return(
        <>
            <div style={{ backgroundColor:"#bacbe8", borderRadius:"15px", paddingRight:"10px", paddingLeft:"10px"}}>
dd

            </div>
        </>
    );
}

export default Recipe;